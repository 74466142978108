import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Polyfills, to replace polyfill.io
 */
import 'core-js/features/symbol';
import 'core-js/features/object/assign';
import 'core-js/features/object/keys';
import '@formatjs/intl-locale/polyfill';
// import '@formatjs/intl-locale/locale-data/en';
// import '@formatjs/intl-locale/locale-data/no';

import { Provider } from 'react-redux'
import { FocusStyleManager } from '@blueprintjs/core'
import { configureStore } from './store/index'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '../node_modules/tachyons/css/tachyons.css'
import './index.css'
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/database'
import { startListeningToAuth } from './store/User/actions'
import App from './containers/App/App.js'
import ErrorBoundry from './components/ErrorBoundry/ErrorBoundry'
import { config } from './config'
import { DevelopmentPanel } from './development/DevelopmentPanel'

// Blueprint accessibility config: http://blueprintjs.com/docs/#a11y
FocusStyleManager.onlyShowFocusOnTabs()

const store = configureStore()

firebase.initializeApp(config.firebase)

const db = firebase.database()
const functions = firebase.functions()
const auth = firebase.auth()

if (config.firebase.useEmulators) {
  auth.useEmulator('http://localhost:9002')
  functions.useEmulator('localhost', 5001)
  db.useEmulator('localhost', 9000)
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundry>
      <App />
      <DevelopmentPanel />
    </ErrorBoundry>
  </Provider>,
  document.getElementById('root')
)

setTimeout(function () {
  store.dispatch(startListeningToAuth())
})
